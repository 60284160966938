import React, {Component} from 'react';
import logo from "./logo.png";
import Menu from "./Menu";
import Content from "./Content";
import {Navbar, Nav, Form, FormControl, Container, Row, Col} from 'react-bootstrap';
import {BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

class App extends Component {


  

  render() {
    
    return(
      <div>
        <BrowserRouter>
        <Container >
          <Row>
          <Col sm><h1 className="App-header" alt="Juadel.com">Juan Delgado</h1></Col>
          </Row>
          <Row>
          <Col sm><Menu/></Col>
          </Row>
          
        
        </Container>
        </BrowserRouter>
        <footer className="App-footer">
          
          
          <p>Juadel.com - 2020</p>
        </footer>
      </div>
      
        
      

    );


  }

}

export default App;
