import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
// import styled from 'styled-components';
import {BrowserRouter,Router, Route, Switch, withRouter} from 'react-router-dom';
import {Navbar, Nav, Form, FormControl} from 'react-bootstrap';
import Projects from './Projects';
import Content from './Content';
import Photography from './Photography';
import './App.css';




class Menu extends Component {

    
  handleRoute = route => () => {
      this.props.history.push({ pathname: route })
      
    };
  
  

  render(){
    return( 
        <div>
            <Nav className="justify-content-center">
                <Nav.Item>
                    <Nav.Link onClick={this.handleRoute("/")}>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={this.handleRoute("/Photography")}>Photography</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={this.handleRoute("/Projects")}>Projects</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="https://blog.juadel.com">Decoding the Cloud</Nav.Link>
                </Nav.Item>
            </Nav>
            <Switch>
                
                <Route path="/Photography" component= {Photography}/>
                <Route path="/Projects" component={Projects}/>
                <Route path="/" component={Content} />
                
          </Switch>
                        
        </div>
      
    )
  
    
  
  
  }
}


export default withRouter(Menu);