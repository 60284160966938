import React, {Component} from 'react';
import {Button, Jumbotron, Image, Row, Col, Container, Card, Modal} from 'react-bootstrap';
// import styled from 'styled-components';
import {BrowserRouter, Route, Switch, withRouter} from 'react-router-dom';
import {Navbar, Nav, Form, FormControl} from 'react-bootstrap';
import './App.css';




class Photography extends Component{

    constructor(props){
        super(props);
        this.state ={
            bucket: "juadel-photograpy",
            allkeys: [],
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            url: process.env.REACT_APP_URL,
            refresh: null,
            showFull: null,
            urlToshowFull: null,

        }
       this.getListofPics = this.getListofPics.bind(this);
       this.handleShowPics = this.handleShowPics.bind(this);
       this.handleShowFull = this.handleShowFull.bind(this);
       this.handleFullPic = this.handleFullPic.bind(this);
       this.handleClose = this.handleClose.bind(this);
        
        
    }
    
    componentDidMount(){
        
        this.getListofPics();
        //this.setState({refresh: false}) ;

       // window.location="/Photograpy";
       
    //    this.handleShowPics();

    }
    handleShowFull(file){
        //console.log()
        this.setState({showFull: true, urlToshowFull: this.state.url+file});
        this.handleFullPic();
    }
    handleClose(){
        this.setState({showFull: false})
    }
    
    handleFullPic(){
        console.log("modal")
        return(
        <div>
            <Modal show={this.state.showFull} onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered >
                 
                    <Image src={this.state.urlToshowFull}/>
                
            </Modal>
        </div>

        )

    }
    

    handleShowPics(){
       
        const files = this.state.allkeys;
        if (this.state.allkeys){
        
        
        return (
            files.map(file =>
                (
                <Col sm={4}>
                    <Card>
                        <Image onClick={() => this.handleShowFull(file)}  src={this.state.url+file} thumbnail/>
                    </Card>
                </Col>
                )
            ))
        }
    }


    async getListofPics(){
        
        const AWS = require('aws-sdk');
        AWS.config.region="ca-central-1";
        let List=[];
        AWS.config.credentials = {accessKeyId: this.state.accessKeyId, secretAccessKey : this.state.secretAccessKey}
        const params = {Bucket: this.state.bucket};
        const S3 = new AWS.S3();
        console.log(AWS.config)
        const response = await S3.listObjectsV2(params).promise();
        response.Contents.forEach(obj => List.push(obj.Key));
        this.setState({allkeys: List, refresh: true});
        
        //console.log(this.state.refresh)
        
        
          


    }

    

    render(){
        // let pics = null
        // if (this.state.refresh){ pics = this.handleShowPics();}
        const full = this.handleFullPic();
             
        
        
        return(<Container>
            <Row><Col><h5><hr/></h5></Col></Row>
            <Row><Col><h5><hr/></h5></Col></Row>
            <Row>{this.handleShowPics()}</Row>
            {full}
            </Container>);
    }

}

export default withRouter(Photography);