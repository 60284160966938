import React, {Component} from 'react';
import {Button, Nav, Image, Row, Col, Card, Media, Container, Carousel, Badge} from 'react-bootstrap';
import {withRouter} from 'react-router-dom';
import './App.css';

class Projects extends Component {

    constructor(props){
        super(props);
        this.state ={

        }
    }



    render(){

        return(
            <Container>
                <Row><Col><h5><hr/></h5></Col></Row>
                <Row><Col><h5><hr/></h5></Col></Row>
                <Row>
                    <Card>
                    <Card.Header> <a id="service_manager">
                        Service Manager</a>
                    </Card.Header>
                    <Card.Text>
                        <Container>
                        <Row>
                        <Col>
                         <p> This is the application that I've been expending my time on recently, after completing the <a href="https://www.udacity.com/course/cloud-developer-nanodegree--nd9990">Cloud developer Nanodegree </a> in 
                            <a href="http://www.udacity.com"> Udacity.com</a>, I decided to get more practice and develop a fully functional full-stack Application.
                        </p>
                        <p>It is a simple platform where users can create and manage service calls, tickets and customers. To be able to grant access, the user must be registered and logged in. I used a Cognito pool for authentication. 
                            Once authenticated, the user can create and access existing tickets or customers, it is possible also, to add files and comments to each ticket. The back-end was developed in Typescript and deployed in AWS using a serverless framework.
                            To manage the database, I used 3 DynamoDB tables, just for purely academic reasons, although a SQL Database is more appropriate for this kind of Application.
                        </p>
                        </Col>
                        </Row>
                        <Row>
                        <Col><figure>
                            <img style={{width: '90%'}}  src="/images/Screenshot.png" alt="General Schematic"/>
                            <figcaption style={{textAlign: 'center'}}> Service Manager - General Schematic</figcaption>
                            </figure>
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>An API Gateway, 6 lambda functions and an S3 bucket were needed to complete resources for the Back-end. I am still working on a Dashboard; Perhaps I am going to need to add more endpoints.
                                   I write the front-end in javascript and React, and it was also for academic reasons, I wanted to learn React.</p><p> To make it close to a real case scenario, I created a subdomain <a href="https://service.juadel.com">service.juadel.com</a> in Route 53, where I have hosted my domain juadel.com.
                                   </p><p>To access Service Manager, you require a user name and password that you can create freely. However, if you want to give it a try without register, use "test" as user and "Test9086" as a password.
                                </p>
                            </Col >
                                
                            
                        </Row>
                        <Row>
                            <Col className="Pro-footer">
                            <Button   variant="info" href="https://github.com/juadel/serviceManager">Github Repo</Button>&nbsp;&nbsp;
                            <Button  variant="info" href="https://service.juadel.com">Service Manager</Button>
                            </Col>    
                        </Row>
                        </Container>    
                        </Card.Text>
                    </Card>
                </Row>
                <Row><Col><h5><hr/></h5></Col></Row>
                {/* new project */}
                <Row>
                    <Card>
                        <Card.Header> 
                            <a id="offocus">Off Focus</a>
                        </Card.Header>
                        <Card.Text>
                            <Container>
                                <Row>
                                    <Col>
                                    <p>Within time, CCTV cameras can become out of focus due to different reasons. 
                                       This program allows users to scan for IP cameras (over an IP range) and determine the shoot image state, 
                                       what could be “CLEAR” or “BLURRY.” It also provides an alternative method to check images located in a folder.
                                       The user can decide if he wants to see the image or just read a final report.
                                    </p>
                                    </Col>
                                </Row>
                                <Row>
                                <Col><figure>
                                    <img style={{width: '30%', margin: 'auto', display: 'block'}}  src="/images/Offocus.png" alt="General Schematic"/>
                                    <figcaption style={{textAlign: 'center'}}> Offocus Finder V1.0 - Graphics Interface</figcaption>
                                    </figure>
                                </Col>
                                </Row>
                                <Row>
                                <Col>
                                    <p>
                                    After long research, I decide to work with three features obtained from the images; Laplacian, Gaussian and Scharr.
                                    </p>
                                    <ul>
                                            <li><p>Laplacian: The Laplacian is a 2-D measure of the 2nd spatial derivative of an image. 
                                                The Laplacian of an image highlights regions of rapid intensity change and is therefore often 
                                                used for edge detection. A right focus image is expected to have well-defined edges. To obtain 
                                                a Laplacian value, I used the <a href="https://scikit-image.org/docs/dev/api/skimage.filters.html#skimage.filters.laplace" >Laplace filter </a> 
                                                from the Scikit-image library.</p><p>With the Laplace value, I then calculated the variance and		
	                                            the maximum value of Laplacian to have more features to compare.</p></li>
                                            <li><p>Gaussian-Noise Standard Deviation: Principal sources of Gaussian noise in digital images arise during acquisition,
                                                (sensor noise) caused by poor illumination and/or high temperature and/or transmission (electronic circuit noise). 
                                                In digital image processing, Gaussian noise can be reduced using a spatial filter. However, when smoothing an image, an undesirable outcome 
                                                may result in the blurring of fine-scaled image edges and details because they also correspond to blocked high frequencies. To calculate this noise, 
                                                I used <a href="https://scikit-image.org/docs/dev/api/skimage.restoration.html#skimage.restoration.estimate_sigma"> estimate_sigma </a> from the Scikit-Image library</p></li>
                                            <li><p>Scharr Filter: This is a filtering method used to identify and highlight gradient edges/features using the 1st derivative. Used to detect edges/changes in pixel intensity. 
                                                Base on the same principle of clear images that must have well-defined boundaries, I choose this filter to have additional features to compare. 
                                                To calculate the Sharr of the image, I used the <a href="https://scikit-image.org/docs/dev/api/skimage.filters.html#skimage.filters.scharr"> Scharr function </a> from the Scikit-Image library and then calculated its variance.</p></li>
                                            
                                    </ul>
                                </Col>
                                </Row>
                                <Row>
                                <Col><figure><a href="/images/features.png">
                                    <img style={{width: '50%', margin: 'auto', display: 'block'}}  src="/images/features.png" alt="General Schematic"/></a>
                                    <figcaption style={{textAlign: 'center'}}> Offocus Finder V1.0 - Features processing</figcaption>
                                    </figure>
                                </Col>
                                </Row>


                                <Row>
                                <Col>
                                    <p>
                                    With this features, I created 4 different  Supervise learning models using 6000 images (half blurry, half-clear). One model with all the features and the other 
                                    tree with varying combinations of features, according to the correlation that I found. If you are interested, you can see the complete process of this model creation in the jupyter 
                                    notebook file "KNeighbors-Multiplemodels.ipynb" located in the <a href="https://github.com/juadel/Offocus-Class">Github Repository. </a> 
                                    </p>
                                </Col>
                                </Row>
                                <Row>
                                <Col> <figure><a href="/images/Blurry1.png">
                                    <img style={{width: '100%', margin: 'auto', display: 'block'}}  src="/images/Blurry1.png" alt="General Schematic"/></a>
                                    <figcaption style={{textAlign: 'center'}}> 100 % Clear image</figcaption>
                                    </figure>
                                </Col>
                                <Col><figure><a href="/images/Blurry2.png">
                                    <img style={{width: '100%', margin: 'auto', display: 'block'}}  src="/images/Blurry2.png" alt="General Schematic"/></a>
                                    <figcaption style={{textAlign: 'center'}}> 25% Blurry</figcaption>
                                    </figure>
                                </Col>
                                <Col><figure><a href="/images/Blurry3.png">
                                    <img style={{width: '100%', margin: 'auto', display: 'block'}}  src="/images/Blurry3.png" alt="General Schematic"/></a>
                                    <figcaption style={{textAlign: 'center'}}> 100 % Clear image</figcaption>
                                    </figure>
                                </Col>
                                <Col><figure><a href="/images/report.png">
                                    <img style={{width: '100%', margin: 'auto', display: 'block'}}  src="/images/report.png" alt="General Schematic"/></a>
                                    <figcaption style={{textAlign: 'center'}}> Final Report</figcaption>
                                    </figure>
                                </Col>
                                </Row>
                                <Row>
                                <Col>
                                    <p>
                                    To make the project more interesting, I moved the comparison process to an EC2 instance using a simple Gunicorn server. The Client has to acquire the shots from the IP cameras or folder,
                                     obtain the 4 features and send it to the Instance. The Instance will respond with the estimation of each model, then the Client, according to the values, calculates a percentage of blurry or clearness.
                                    </p>
                                </Col>
                                </Row>
                                <Row>
                                <Col className="Pro-footer">
                                <Button   variant="info" href="https://github.com/juadel/Offocus-Class">Github Repo</Button>&nbsp;&nbsp;
                                
                                </Col>    
                                </Row>
                            </Container>
                        </Card.Text>

                    </Card>
                </Row>
                 {/* new project */}
                 <Row>
                    <Card>
                        <Card.Header> 
                            <a id="suspect">Suspect Detector</a>
                        </Card.Header>
                        <Card.Text>
                            <Container>
                                <Row>
                                    <Col>
                                    <p>Suspect Detector is a python based application that using an IP camera detects faces and recognizes them according to a 
                                        provided Database. Once the App detects the suspect, triggers the AWS Lambda function, 
                                        and the user received a text message with the suspect information. </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <p> I divided the project into Backend (the lambda function) and the Frontend or client. The Frontend has six components. </p>
                                    <ul>
                                        <li> 
                                        The database is a file where the user saves every suspect information, 
                                        face and name, the file es handle using pickle.
                                        </li>
                                        <li>
                                        An Event Log that keeps a record of the time the suspect is detected, 
                                        this helps to avoid repeated detections and alarms.
                                        </li>
                                        <li>
                                        To detect faces and compare, I used face_recognition, a fantastic library from <a href="https://github.com/ageitgey">Adam Geitgey</a>.   
                                        I Also used OpenCV - cv2 to handle the video capture and processing frames.
                                        </li>
                                        <li>
                                        A settings file saves the IP Camera settings and phone number information. 
                                        </li>
                                        <li>
                                        To trigger the message, I used a simple post request to the lambda function endpoint.
                                        </li>
                                        <li>
                                        For the user interface, I used a simple graphic interface using the Tkinter library. 
                                        </li>
                                    </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                <Col><figure><a href="/images/awsconsole.png">
                                    <img style={{width: '90%', margin: 'auto', display: 'block'}}  src="/images/awsconsole.png" alt="General Schematic"/></a>
                                    <figcaption style={{textAlign: 'center'}}> AWS Console - Lambda function</figcaption>
                                    </figure>
                                </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <p>
                                    For the Backend, in this case, because it is just a simple lambda function, 
                                    I used the AWS console directly to create it.
                                    </p>
                                    </Col>
                                </Row>
                                <Row>
                                <Col className="Pro-footer">
                                <Button variant="info" href="https://github.com/juadel/SuspectDetecion">Github Repo</Button>&nbsp;&nbsp;
                                
                                </Col>    
                                </Row>
                            </Container>
                        </Card.Text>

                    </Card>
                </Row>
                


            </Container>


        )


    }

}
export default withRouter(Projects);