import React, {Component} from 'react';
import {Button, Jumbotron, Image, Row, Col, Card, Media, Container, Carousel, Badge} from 'react-bootstrap';
import styled from 'styled-components'
import {LinkedinSquare} from 'styled-icons/boxicons-logos/LinkedinSquare';
import {Github} from 'styled-icons/boxicons-logos/Github';
import {Instagram} from 'styled-icons/boxicons-logos/Instagram'
import './App.css';

const LinkedinIcon = styled(LinkedinSquare)`
  color: black;
  width: 80px;
  height: 40px;
`
const GithubIcon = styled(Github)`
  color: black;
  width: 80px;
  height: 40px;
`
const InstagramIcon = styled(Instagram)`
  color: black;
  width: 80px;
  height: 40px;
`
class Content extends Component{

    constructor(props){
        super(props);
       
    }

    render (){
        return(
        
            <Container>
              <Row><Col><h5><hr/></h5></Col></Row>
              <Row><Col><h5><hr/></h5></Col></Row>
              <Row>
                <Col>
                <Media> 
                  {/* <Image className="Icon" src={juanlogo} roundedCircle fluid/> */}
                  <img width={100} height={100} className="align-self-center mr-3" src="/images/juanlogo.jpg" alt="Generic placeholder"/>
                  <Media.Body>
                  <h5> Hello, I'm Juan</h5>
                  <p>
                    I'm a father of two, Husband, Photography enthusiast, Engineer. Project Management 
                    has been part of my background for many years, although I enjoy it, I found software development 
                    inspiring and creative. 
                  </p>
                  </Media.Body>
                </Media>
                </Col>
              </Row>
              <Row className="Icons">
                <Col>
                <a href="https://www.linkedin.com/in/juadelm" ><LinkedinIcon/></a>
                <a href="https://github.com/juadel" ><GithubIcon/></a>
                <a href="https://www.instagram.com/juadel/" ><InstagramIcon/></a>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5><hr/></h5>
                </Col>
              </Row>
            
              
              <Row>
                <Col sm={4} >    
                <Card style={{top: '10px'}}>
                  <Card.Header>
                  <h5>Some of my shots </h5>
                  </Card.Header>
                                     
                  <Carousel controls={false} fade={true} >
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2597.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2610.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2635.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2703-1.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2714a.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2736.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2927.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_2956.jpg" alt="First slide"/>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img  className="Carousel" src="/images/DSC_3314.jpg" alt="First slide"/>
                    </Carousel.Item>
                  </Carousel>
                  <Card.Text>
                    <br/>
                    <p > <h6>I also love photography! Check out my photo library</h6></p>
                  </Card.Text>
                  <Button variant="dark" href="/Photography">Photo Library</Button>
                  </Card>
                </Col>
                <Col >
                <Card style={{top: '10px'}}>
                  <Card.Header>
                    <h5>Projects</h5>
                  </Card.Header>
                  <Card.Text>
                  
                    <Media>
                      <img width={100} height={100} className="align-self-center mr-3" src="/images/serverless.png" alt="Generic placeholder"/>
                      <Media.Body>
                        <h4><a href="/projects/#service_manager">Service Manager</a></h4>
                        <p>A full-stack Cloud-based application to keep track of service tickets, The user must be signed in to create or modify customers and services. 
                          Once created, the user can upload files and add comments to each service. </p>
                        <div>
                          <Badge variant="secondary"  >Typescript </Badge>&nbsp;
                          <Badge variant="secondary" >Javascript </Badge>&nbsp;
                          <Badge variant="secondary" >React</Badge>&nbsp;
                          <Badge variant="secondary" >Serverless </Badge>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <a href="https://service.juadel.com"><Badge variant="success"> Service: Online </Badge></a>
                        </div>
                      </Media.Body>
                    </Media>
                    <div><hr/></div>
                    <Media>
                      <img width={100} height={100} className="align-self-center mr-3" src="/images/python.png" alt="Generic placeholder"/>
                      <Media.Body>
                        <h4><a href="/projects/#offocus">Off Focus</a></h4>
                        <p> Application to detect blurry images. To predict results, this App uses a machine learning model deployed in an EC2 instance</p>
                        <div>
                          <Badge variant="secondary" > Python </Badge>&nbsp;
                          <Badge variant="secondary" > Machine Learning </Badge>&nbsp;
                          <Badge variant="secondary" > OpenCV </Badge>&nbsp;
                          <Badge variant="secondary" > AWS EC2 </Badge>
                          
                        </div>
                      </Media.Body>
                    </Media>
                    <div><hr/></div>
                    <Media>
                      <img width={100} height={100} className="align-self-center mr-3" src="/images/lambda.png"  alt="Generic placeholder"/>
                      <Media.Body>
                        <h4><a href="/projects/#suspect">Suspect Detection</a></h4>
                        <p>This application search for faces through an IP camera and compare each face with a database file;  
                          if a suspect is detected, a Lambda function is triggered to send a text message to a provided cellphone.</p>
                        <div>
                          <Badge variant="secondary"  >Face Detection</Badge>&nbsp;
                          <Badge variant="secondary" >Python </Badge>&nbsp;
                          <Badge variant="secondary" >AWS</Badge>&nbsp;
                          <Badge variant="secondary" > Lambda </Badge>
                        </div>
                      </Media.Body>
                    </Media>
                   
                  </Card.Text>
                </Card>
                </Col>
              </Row>
              <Row><Col><h5><hr/></h5></Col></Row>
              <Row><Col><h5><hr/></h5></Col></Row>
              
            </Container>
            
         
            
          
            
          
          );
    }
}

export default Content;